import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import axios from "axios";
import { toast } from "react-toastify";
import { AuthContext } from "../Context/AuthContext";

const Navbar = () => {
  const [show, setShow] = useState(false);
  const { isAuthenticated, setIsAuthenticated, setUser, setToken } =
    useContext(AuthContext);

  const handleLogout = async () => {
    await axios
    .get(
      import.meta.env.VITE_APP_BASE_API +
      import.meta.env.VITE_APP_API_VERSION +
      "/user/patient/logout",
      {
        withCredentials: true,
      }
    )
    .then((res) => {
      toast.success(res.data.message);
      setIsAuthenticated(false);
      setUser({});
      setToken(null);
      localStorage.removeItem("token");
      window.location.href = "/";
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
      setShow(!show);
  };

  const navigateTo = useNavigate();

  const goToLogin = () => {
    navigateTo("/login");
    setShow(!show);
  };
  const goToProfile = () => {
    navigateTo("/profile");
    setShow(!show);
  };

  return (
    <nav className={"container"}>
        <div className="logo">
          <img src="/logo-bw.png" alt="logo" className="logo-img" />
        </div>
        <div className={show ? "navLinks" : "navLinks showmenu"}>
          <div className="links">
            <Link to={"/"} onClick={() => setShow(!show)}>
              Home
            </Link>
            <Link to={"/appointment"} onClick={() => setShow(!show)}>
              Appointment
            </Link>
            <Link to={"/about"} onClick={() => setShow(!show)}>
              About Us
            </Link>
          </div>
          {isAuthenticated ? (
            <div className="flex gap-2">
              <button className="logoutBtn btn" onClick={goToProfile}>
                Profile
              </button>
              <button className="logoutBtn btn" onClick={handleLogout}>
                LOGOUT
              </button>
            </div>
          ) : (
            <button className="loginBtn btn" onClick={goToLogin}>
              LOGIN
            </button>
          )}
        </div>
        <button className="hamburger" onClick={() => setShow(!show)}>
          <GiHamburgerMenu />
        </button>
      </nav>
  );
};

export default Navbar;
