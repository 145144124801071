import React from "react";

const Biography = ({imageUrl}) => {
  return (
    <>
      <div className="container biography">
        <div className="banner">
          <img src={imageUrl} alt="whoweare" />
        </div>
        <div className="banner">
          <p>Biography</p>
          <h3>Who We Are</h3>
          <p>
          Smart Nurse is at the forefront of transforming the healthcare landscape with cutting-edge technology and compassionate care. Our innovative platform integrates advanced artificial intelligence to provide unparalleled support and assistance to patients and healthcare professionals alike.
          At the heart of Smart Nurse is our dedicated team of experts who are committed to enhancing the quality of healthcare delivery through innovation and excellence. With backgrounds spanning healthcare, technology, and AI development, our team brings together diverse skills and experiences to create a seamless and intelligent healthcare ecosystem.
          </p>
          <p>We are all in 2024!</p>
          <p>We are working on an AI PROJECT.</p>
          <p>
Driven by a mission to empower individuals to take control of their health and well-being, Smart Nurse leverages AI algorithms to deliver personalized care solutions tailored to each patient's unique needs. Whether it's monitoring vital signs, providing medication reminders, or offering virtual consultations, our AI-powered platform ensures continuous support and guidance, 24/7.

With Smart Nurse, patients experience a new level of convenience and peace of mind, knowing that they have a dedicated virtual companion to assist them every step of the way. Our platform not only streamlines healthcare processes but also fosters a deeper connection between patients and their care providers, leading to improved outcomes and satisfaction.
          </p>
        </div>
      </div>
    </>
  );
};

export default Biography;
