import React, { useEffect, useState } from 'react';
import Navbar from '../../components/Navbar';
import { useParams } from 'react-router-dom';

const QuestionsAndAnswers = () => {
    const [questionsAndAnswers, setQuestionsAndAnswers] = useState([]);
const {id} = useParams();


useEffect(() => {
    const storedQa = localStorage.getItem('selectedQuestion');
    if (storedQa) {
      setQuestionsAndAnswers(JSON.parse(storedQa));
    }
  }, [id]);

console.log(id);
  return (
    <div className="min-h-screen flex flex-col items-center bg-gray-100 p-5 mt-[100px]">
         <Navbar /> 
      <div className="w-full max-w-4xl">
        {questionsAndAnswers.map((qa, index) => (
          <div key={index} className="p-4 bg-gradient-to-r from-blue-500 to-blue-300 rounded-lg shadow-md mb-4">
            <p className="text-white font-bold">Q - {qa.question}</p>
            <p className="text-white mt-2">A - {qa.answer}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default QuestionsAndAnswers;
