import { useRef, useState } from "react";
import {
  Button,
  Box,
  Typography,
  AppBar,
  Toolbar,
  IconButton,
} from "@mui/material";
import { Link } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";

const VisualDataImage = () => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [imageData, setImageData] = useState(null);
  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      videoRef.current.srcObject = stream;
      videoRef.current.play();
    } catch (error) {
      console.error("Error accessing camera:", error);
    }
  };
  const captureImage = () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    context.drawImage(video, 0, 0, canvas.width, canvas.height);
    const imageDataUrl = canvas.toDataURL("image/png");
    setImageData(imageDataUrl);
  };

  return (
    <Box>
      <AppBar position="static">
        <Toolbar>
          <Link to={"/visual-data"}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="back"
              sx={{ mr: 2 }}
            >
              <IoMdArrowBack />
            </IconButton>
          </Link>
          <Typography variant="h6">Camera Image Capture</Typography>
        </Toolbar>
      </AppBar>

      <Box display="flex" flexDirection="column" alignItems="center" p={2}>
        <Box display="flex" flexDirection="column" alignItems="center" mb={2}>
          <video
            ref={videoRef}
            width="100%"
            autoPlay
            style={{ maxWidth: "400px" }}
          ></video>
        </Box>

        <Button variant="contained" color="primary" onClick={startCamera}>
          Start Camera
        </Button>

        <Button
          variant="contained"
          color="secondary"
          onClick={captureImage}
          style={{ marginTop: "10px" }}
        >
          Capture Image
        </Button>
        <canvas ref={canvasRef} style={{ display: "none" }} />
        {imageData && (
          <Box mt={2}>
            <Typography variant="subtitle1">Captured Image:</Typography>
            <img
              src={imageData}
              alt="Captured"
              style={{ width: "100%", maxWidth: "400px" }}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default VisualDataImage;
