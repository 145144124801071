import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { IoMdArrowBack } from "react-icons/io";
import {
  Card, ListItem, ListItemText
} from "@mui/material";
import { FaCamera, FaVideo } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";

export default function VisualData() {
  const navigate = useNavigate();

  const goto = (path) => {
    navigate(`/${path}`);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar>
        <Toolbar>
          <Link to={"/"}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="back"
              sx={{ mr: 2 }}
            >
              <IoMdArrowBack />
            </IconButton>
          </Link>

          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Visuals Data
          </Typography>
        </Toolbar>
      </AppBar>
      <Card className="m-3 mt-14">
        <ListItem alignItems="flex-start">
          <ListItemText
            primary={
              <React.Fragment>
                <Typography
                  sx={{ display: "inline", marginTop: "20px", fontWeight: 600 }}
                  component="h5"
                  variant="h5"
                  color="text.primary"
                >
                  Image
                </Typography>
              </React.Fragment>
            }
            secondary={
              <React.Fragment>
                <Typography
                  sx={{ display: "inline", marginTop: "20px" }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  Capture Camera Images
                </Typography>
              </React.Fragment>
            }
          />
        </ListItem>
        <Toolbar>
          <Button onClick={() => goto("visual-data/image")} variant="contained" startIcon={<FaCamera />}>
            Capture an Image
          </Button>
        </Toolbar>
      </Card>
      <Card className="m-3 mt-4">
        <ListItem alignItems="flex-start">
          <ListItemText
            primary={
              <React.Fragment>
                <Typography
                  sx={{ display: "inline", marginTop: "20px", fontWeight: 600 }}
                  component="h5"
                  variant="h5"
                  color="text.primary"
                >
                  Video
                </Typography>
              </React.Fragment>
            }
            secondary={
              <React.Fragment>
                <Typography
                  sx={{ display: "inline", marginTop: "20px" }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  Record video from device camera
                </Typography>
              </React.Fragment>
            }
          />
        </ListItem>
        <Toolbar>
          <Button onClick={() => goto("visual-data/video")}  variant="contained" startIcon={<FaVideo />}>
            Capture a Video
          </Button>
        </Toolbar>
      </Card>
    </Box>
  );
}
