import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Container, TextField, Button, Box, Grid, Select, MenuItem, FormControl, InputLabel, Checkbox, FormControlLabel } from "@mui/material";

const AppointmentForm = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [nic, setNic] = useState("");
  const [dob, setDob] = useState("");
  const [gender, setGender] = useState("");
  const [appointmentDate, setAppointmentDate] = useState("");
  const [department, setDepartment] = useState("Pediatrics");
  const [doctorFirstName, setDoctorFirstName] = useState("");
  const [doctorLastName, setDoctorLastName] = useState("");
  const [address, setAddress] = useState("");
  const [hasVisited, setHasVisited] = useState(false);

  const departmentsArray = ["Pediatrics", "Orthopedics", "Cardiology", "Neurology", "Oncology", "Radiology", "Physical Therapy", "Dermatology", "ENT"];

  const [doctors, setDoctors] = useState([]);
  useEffect(() => {
    const fetchDoctors = async () => {
      const { data } = await axios.get(import.meta.env.VITE_APP_BASE_API + import.meta.env.VITE_APP_API_VERSION + "/user/doctors", { withCredentials: true });
      setDoctors(data.doctors);
      console.log(data.doctors);
    };
    fetchDoctors();
  }, []);

  const handleAppointment = async (e) => {
    e.preventDefault();
    try {
      const hasVisitedBool = Boolean(hasVisited);
      const { data } = await axios.post(
        import.meta.env.VITE_APP_BASE_API + import.meta.env.VITE_APP_API_VERSION + "/appointment/post",
        {
          firstName,
          lastName,
          email,
          phone,
          nic,
          dob,
          gender,
          appointment_date: appointmentDate,
          department,
          doctor_firstName: doctorFirstName,
          doctor_lastName: doctorLastName,
          hasVisited: hasVisitedBool,
          address,
        },
        {
          withCredentials: true,
          headers: { "Content-Type": "application/json" },
        }
      );
      toast.success(data.message);
      setFirstName("");
      setLastName("");
      setEmail("");
      setPhone("");
      setNic("");
      setDob("");
      setGender("");
      setAppointmentDate("");
      setDepartment("Pediatrics");
      setDoctorFirstName("");
      setDoctorLastName("");
      setHasVisited(false);
      setAddress("");
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <>
      <Container className="container form-component appointment-form">
        <Box my={4}>
          <h2>Appointment</h2>
          <form onSubmit={handleAppointment}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField fullWidth label="First Name" variant="outlined" margin="normal" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField fullWidth label="Last Name" variant="outlined" margin="normal" value={lastName} onChange={(e) => setLastName(e.target.value)} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField fullWidth label="Email" type="email" variant="outlined" margin="normal" value={email} onChange={(e) => setEmail(e.target.value)} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField fullWidth label="Mobile Number" type="number" variant="outlined" margin="normal" value={phone} onChange={(e) => setPhone(e.target.value)} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField fullWidth label="NIC" type="number" variant="outlined" margin="normal" value={nic} onChange={(e) => setNic(e.target.value)} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField fullWidth label="Date of Birth" type="date" InputLabelProps={{ shrink: true }} variant="outlined" margin="normal" value={dob} onChange={(e) => setDob(e.target.value)} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth margin="normal">
                  <InputLabel>Gender</InputLabel>
                  <Select value={gender} onChange={(e) => setGender(e.target.value)} label="Gender">
                    <MenuItem value="Male">Male</MenuItem>
                    <MenuItem value="Female">Female</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField fullWidth label="Appointment Date" type="date" InputLabelProps={{ shrink: true }} variant="outlined" margin="normal" value={appointmentDate} onChange={(e) => setAppointmentDate(e.target.value)} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth margin="normal">
                  <InputLabel>Department</InputLabel>
                  <Select
                    value={department}
                    onChange={(e) => {
                      setDepartment(e.target.value);
                      setDoctorFirstName("");
                      setDoctorLastName("");
                    }}
                    label="Department"
                  >
                    {departmentsArray.map((depart, index) => (
                      <MenuItem value={depart} key={index}>
                        {depart}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth margin="normal">
                  <InputLabel>Doctor</InputLabel>
                  <Select
                    value={`${doctorFirstName} ${doctorLastName}`}
                    onChange={(e) => {
                      const [firstName, lastName] = e.target.value.split(" ");
                      setDoctorFirstName(firstName);
                      setDoctorLastName(lastName);
                    }}
                    label="Doctor"
                    disabled={!department}
                  >
                    <MenuItem value="">Select Doctor</MenuItem>
                    {doctors
                      .filter((doctor) => doctor.doctorDepartment === department)
                      .map((doctor, index) => (
                        <MenuItem value={`${doctor.firstName} ${doctor.lastName}`} key={index}>
                          {doctor.firstName} {doctor.lastName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth label="Address" variant="outlined" margin="normal" multiline rows={4} value={address} onChange={(e) => setAddress(e.target.value)} />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel control={<Checkbox checked={hasVisited} onChange={(e) => setHasVisited(e.target.checked)} />} label="Have you visited before?" />
              </Grid>
            </Grid>
            <Box display="flex" justifyContent="center" alignItems="center" my={2}>
              <Button type="submit" variant="contained" color="primary" style={{ marginTop: "20px" }} className="submit-button">
                GET APPOINTMENT
              </Button>
            </Box>
          </form>
        </Box>
      </Container>
    </>
  );
};

export default AppointmentForm;
