import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

const AppointmentVerification = () => {
  const { appointmentId } = useParams();
  const [verificationResult, setVerificationResult] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // Construct the API URL using environment variables
  const API_BASE = import.meta.env.VITE_APP_BASE_API;
  const API_VERSION = import.meta.env.VITE_APP_API_VERSION;
  const API_ENDPOINT = `${API_BASE}${API_VERSION}/appointment/accept`; // Updated endpoint path

  useEffect(() => {
    const verifyAppointment = async () => {
      setIsLoading(true);
      setError(null);
      setVerificationResult(null);

      if (!appointmentId) {
        setError("Appointment ID is missing.");
        setIsLoading(false);
        return;
      }

      try {
        const response = await fetch(`${API_ENDPOINT}/${appointmentId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData?.message || `HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setVerificationResult(data);
      } catch (err) {
        setError(err.message);
        console.error("Error verifying appointment:", err);
      } finally {
        setIsLoading(false);
      }
    };

    verifyAppointment();
  }, [appointmentId, API_ENDPOINT]); // Add API_ENDPOINT to the dependency array

  if (isLoading) {
    return (
      <div className="container">
        <h1>Verifying Appointment...</h1>
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="container mt-5"> {/* Added margin-top for spacing */}
      <div className="row justify-content-center"> {/* Center the content */}
        <div className="col-md-6"> {/* Limit width on larger screens */}
          <div className="card shadow"> {/* Added shadow for depth */}
            <div className="card-body text-center"> {/* Center text within card */}
              {error && (
                <div className="alert alert-danger" role="alert">
                  {error}
                </div>
              )}

              {verificationResult && verificationResult.success && ( // Check for success
                <>
                  <i className="bi bi-check-circle-fill text-success display-1 mb-3"></i> {/* Large success icon */}
                  <h2 className="card-title text-success">Success!</h2> {/* Clearer title */}
                  <p className="card-text">{verificationResult.message}</p> {/* Message as a paragraph */}
                </>
              )}

              {verificationResult && !verificationResult.success && ( // Handle failure case
                <>
                  <i className="bi bi-x-circle-fill text-danger display-1 mb-3"></i>
                  <h2 className="card-title text-danger">Failed!</h2>
                  <p className="card-text">{verificationResult.message || "Appointment update failed."}</p> {/* Provide a default message if none is available */}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppointmentVerification;