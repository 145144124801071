import React, { useEffect, useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  TextField,
  Button,
  Box,
  Grid,
  Avatar,
  CircularProgress,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const EditProfilePage = () => {
  const [person, setPerson] = useState({
    userEmail: '',
    fname: '',
    lname: '',
    mobile: '',
    address: '',
    category: '',
    alexa: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const data = localStorage.getItem('userDetails');
    const email = localStorage.getItem('email');
    if (!data) {
      setPerson((prev) => ({ ...prev, userEmail: email }));
    } else {
      const userDetails = JSON.parse(data);
      setPerson({
        userEmail: email,
        fname: userDetails.fname,
        lname: userDetails.lname,
        mobile: userDetails.mobile,
        address: userDetails.address,
        category: userDetails.category,
        alexa: userDetails.alexa,
      });
    }

    if (!localStorage.getItem('authToken')) {
      navigate('/login');
    } else {
      const { username } = getUserDetails();
      fetchUserDetails(username);
    }
  }, [navigate]);

  const getUserDetails = () => {
    // Implement the function to get user details
  };

  const fetchUserDetails = async (username) => {
    setIsLoading(true);
    try {
      const response = await axios.get(`/api/user/${username}`);
      if (response.data.status) {
        const res = response.data.data;
        setPerson({
          userEmail: res.email,
          fname: res.first_name,
          lname: res.last_name,
          mobile: res.mobile,
          address: res.address,
          category: res.user_type,
          alexa: res.alexa_email,
        });
      }
    } catch (error) {
      console.error('error response', error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateUserDetails = async (user) => {
    setIsLoading(true);
    try {
      const response = await axios.put('/api/user', user);
      if (response.data.status) {
        localStorage.setItem(
          'userDetails',
          JSON.stringify({
            first_name: user.fname,
            last_name: user.lname,
            mobile: user.mobile,
            address: user.address,
            category: person.category,
            email: person.userEmail,
          })
        );
        navigate('/show-profile');
      } else {
        console.error('Update failed');
      }
    } catch (error) {
      console.error('error response', error);
      if (error.response?.data?.message === 'The incoming token has expired') {
        navigate('/login');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPerson((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6">Edit Profile</Typography>
        </Toolbar>
      </AppBar>
      <Box padding={4}>
        {isLoading ? (
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12} className="flex justify-center">
              <Avatar src="/assets/imgs/male.png" sx={{ width: 100, height: 100 }} />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="First Name"
                name="fname"
                value={person.fname}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Last Name"
                name="lname"
                value={person.lname}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Mobile Number"
                name="mobile"
                value={person.mobile}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Address"
                name="address"
                value={person.address}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Alexa ID"
                name="alexa"
                value={person.alexa}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="outlined"
                color="secondary"
                fullWidth
                onClick={() => updateUserDetails(person)}
              >
                Update
              </Button>
            </Grid>
          </Grid>
        )}
      </Box>
    </div>
  );
};

export default EditProfilePage;
